import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Fab, FormControlLabel, Step, StepButton, StepContent, StepLabel, Stepper, Switch, Tab, Tabs} from '@material-ui/core';
import JHApi from "../../utils/JHApi"
import AdminJHApi from "../../utils/AdminJHApi"
import { PersonalJourneyClasses } from '../../styles/PersonalJourneyClasses';
import { dateAsString, getInternationalPhone, getNameFromEmail, PESONAL_JOURNEY_STEPS, SENIORITIES_LIST } from '../../utils/utils';
import { CoverLetterStep, CVStep, ExcludeDetailsStep, PersonalJourneyExplainNew, TestEmailStep, TitleDetailsStep } from './PersonalJourneySteps';
import { PublishStep } from './PublishStep';
import FieldWithEdit from '../FieldWithEdit';
import UserAvatar from '../../containers/UserAvatar';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import JobsFound from './personalJourney/JobsFound';
import CVCover from './personalJourney/CVCover';
import Interviews from './personalJourney/Interviews';
import Social from './personalJourney/Social';
import DreamJob from './personalJourney/DreamJob';
import SelectAsChip from './personalJourney/SelectAsChip';
import UniqueColor from '../../containers/UniqueColor';
import Comments from './personalJourney/Comments';
import UserInfo from '../../containers/UserInfo';
import SafetyNet from './personalJourney/SafetyNet';
import ToggleList from '../../containers/ToggleList';
import Projects from './personalJourney/Projects';
import Journey from './personalJourney/Journey';
import Subscription from './personalJourney/Subscription';
import DashboarDialog from './personalJourney/DashboarDialog';
import ReactGA from 'react-ga4'
//import UpcomingMeeting from './personalJourney/UpcomingMeeting';

/*function StepsButton(props) {
    const classes = PersonalJourneyClasses(); 

    const currentStep = props.currentStep || 0

    return (
        <>
            <Button className={classes.backStepButton} disabled={props.isLoading || (props.showedStep === 0)} onClick={props.handleBackStep} variant="outlined" color="primary" size="small" aria-label="Back">Back</Button>
            <Button className={classes.nextStepButton} disabled={props.isLoading || (props.showedStep === 5) || (currentStep === 4 && props.showedStep === 4)} onClick={props.handleNextStep} variant="contained" color="primary" size="small" aria-label="Next Step">Next Step</Button>
        </>
    )
}*/

export function PersonalJourneyContent(props) {
    const isAdmin = props.admin
    const userId = props.userId
    const isActiveJHUser = props.isActiveJHUser || isAdmin
    const autoRefreshList = props.autoRefreshList

    const [tabSelected, setTabSelected] = useState(0);

    const [showedStep, setShowedStep] = useState(0);
    const [journeyData, setJourneyData] = useState({});
    const [explenations, setExplenations] = useState({});
    const [CSManagers, setCSManagers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [plans, setPlans] = useState([]);
    const [extraData, setExtraData] = useState({});
    //const [resumes, setResumes] = useState([]);
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const [freezeHistory, setFreezeHistory] = useState([]);
    const [signedDate, setSignedDate] = useState();
    //const [upcomingMeeting, setUpcomingMeeting] = useState();
    //const [isError, setIsError] = useState(false);

    const [uplaodMessage, setUplaodMessage] = useState('');
    const [showJourney, setShowJourney] = useState(false);
    const [showDashboard, setShowDashboard] = useState(false);

    //const sawExplenations = journeyData.sawExplenations || {}

    useEffect(() => {
        let paramsToPass = {getExplanations : true}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoadingAll(true)

        JHApi.getPersonalJourneyData(paramsToPass,(response)=>{
            if(response){
                setJourneyData(response.personaljourney);
                if (response.cycles || response.publishData || response.upcomingPublish){
                    setExtraData({cycles:response.cycles||[], publishData:response.publishData||{}, upcomingPublish:response.upcomingPublish})
                }
                if (response.explenations){
                    setExplenations(response.explenations)
                }
                if (response.CSManagers){
                    setCSManagers(response.CSManagers)
                }
                if (response.statuses){
                    setStatuses(response.statuses)
                }
                if (response.plans){
                    setPlans(response.plans)
                }
                setFreezeHistory(response.freezeHistory || [])
                setSignedDate(response.signedDate)
                /*if (response.upcomingMeeting){
                    setUpcomingMeeting(response.upcomingMeeting)
                }*/
                setShowedStep(response.personaljourney.currentStep)
            } else {
                setJourneyData({});
                setExtraData({})
                setExplenations({})
                setCSManagers([])
                setStatuses([])
                setPlans([])
                setShowedStep(0)
                setFreezeHistory([])
                setSignedDate()
            }

            setIsLoadingAll(false)
        }, (error) => {
            if(error && error.response&& error.response.data&& error.response.data.error){
                console.log(error.response.data.error)     
            } else {
                console.log('Something went wrong')
            }
            setJourneyData({});
            setExtraData({})
            setExplenations({})
            setCSManagers([])
            setStatuses([])
            setPlans([])
            setShowedStep(0)

            setIsLoadingAll(false)
            //setIsError(true)
        })
    }, [isAdmin, userId]);

    const handleStep = step => {
        setShowedStep(step)
    }

    const handleSaveData = (data, onSuccess, onError) => {
        let paramsToPass = {data}

        if (isAdmin){
            paramsToPass.userId = userId
        }
    
        setIsLoading(true)

        JHApi.updatePersonalJourneyData(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            if(response.setStep){
                setShowedStep(response.personaljourney.currentStep)
            }
            if (onSuccess){
                onSuccess(response)
            }
        }, (error) => {
            setIsLoading(false)
            //setIsError(true)
            if (onError){
                onError(error)
            }
        })
    }

    const uploadPublishCVFile = event => {
        const maxUploadResumeSize = 50 * 1024; // 50 KB
        const file = event.target.files[0];

        if (!file) {
            return null
        }

        if(file.size > maxUploadResumeSize){
            setUplaodMessage('File too big - 50 KB Max')
            return null
        }
        
        const extension =  file.name.split('.').pop();
        if(!extension.match(/docx|pdf/ig)){
            setUplaodMessage('Invalid extension')
            return null
        }

        setIsLoading(true)
        setUplaodMessage('Uploading...')

        const data = new FormData()
        data.append('file', file, file.name)

        if (isAdmin){
            data.append('userId', userId)
        }

        JHApi.uploadPublishCVFile(data, (response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            setUplaodMessage('')
        }, (error)=>{
            setIsLoading(false)
            setUplaodMessage(error)
        });
    }

    const converToDocx = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.convertGoogleDocsToDocx(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            props.showMessageDialog('Convert Completed', 
                `Check the file, if incorect:
                1. Open CV on Google docs
                2. Select and copy all content of file (Ctrl + A, then Ctrl + C)
                3. Open new Word file, paste the content (Ctrl + V) 
                4. Save as Word file (.docx)
                5. Upload Word file here`, true)
        }, (error) => {
            if(error && error.response&& error.response.data){
                console.log(error.response.data)     
            } else {
                console.log(error)
            }

            props.showMessageDialog('Convert Failed', 
                `Check the file, if incorect:
                - Validate that the file is Google docs
                - Validate is shared with everyone
                If none of this help:
                1. Open CV on Google docs
                2. Select and copy all content of file (Ctrl + A, then Ctrl + C)
                3. Open new Word file, paste the content (Ctrl + V) 
                4. Save as Word file (.docx)
                5. Upload Word file here`, true)
            
            setIsLoading(false)
            //setIsError(true)
        })
    }

    const converToPdf = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.convertGoogleDocsToPdf(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            props.showMessageDialog('Convert Completed', `Check the file`)
        }, (error) => {
            if(error && error.response&& error.response.data){
                console.log(error.response.data)     
            } else {
                console.log(error)
            }

            props.showMessageDialog('Convert Failed', 
                `Check the file, if incorect:
                - Validate that the file is Google docs
                - Validate is shared with everyone`)
            
            setIsLoading(false)
            //setIsError(true)
        })
    }

    const sendTest = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.sendPersonalJourneyTest(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            props.showMessageDialog('Email sent', 'Test sent to email, please check your inbox')
        }, (error) => {
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
            //setIsError(true)
        })
    }

    const startPublish = (beginPublish) => {
        let paramsToPass = {beginPublish}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.startPersonalJourneyPublish(paramsToPass,(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
        })
    }

    const updatePublish = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.updatePersonalJourneyPublish(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
        }, (error) => {
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
        })
    }

    const handleNextStep = () => {
        if (showedStep === journeyData.currentStep && showedStep<=5){
            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setIsLoading(true)

            JHApi.finishPersonalJourneyStep(paramsToPass,(response)=>{
                setJourneyData(response.personaljourney);
                setIsLoading(false)
                setShowedStep(response.personaljourney.currentStep)
            }, (error) => {
                if(error && error.response&& error.response.data && error.response.data.error){
                    props.showMessageDialog('Error', error.response.data.error)
                } else {
                    props.showMessageDialog('Error', 'Something went wrong')
                }
                
                setIsLoading(false)
            })
        } else {
            setShowedStep(showedStep+1)
        }
    }

    /*const handleAddResume = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.addResume(paramsToPass,(response)=>{
            setResumes(response.resumes)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            //setIsError(true)
        })
    }

    const handleUpdateResume = params => {
        let paramsToPass = params

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.updateResume(paramsToPass,(response)=>{
            setResumes(response.resumes)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            //setIsError(true)
        })
    }*/

    /*const handleBackStep = () => {
        if (showedStep !== 0){
            setShowedStep(showedStep-1)
        }
    }*/

    const preServerCall = () => {
        setIsLoading(true)
    }

    const postServerCall = (error, response) => {
        if(error){
            props.showMessageDialog('Error', error)
        } else {
            updateDataByRespone(response)
        }

        setIsLoading(false)
    }

    const updateDataByRespone = response => {
        if(response){
            if(response.personaljourney){
                setJourneyData(response.personaljourney);
                if(!response.dontChangeStep){
                    setShowedStep(response.personaljourney.currentStep)
                }
            }
            if (response.cycles || response.publishData || response.upcomingPublish){
                setExtraData({cycles:response.cycles||[], publishData:response.publishData||{}, upcomingPublish:response.upcomingPublish})
            }
            if (response.explenations){
                setExplenations(response.explenations)
            }
            if (response.CSManagers){
                setCSManagers(response.CSManagers)
            }
            /*if (response.upcomingMeeting){
                setUpcomingMeeting(response.upcomingMeeting)
            }*/
            if (autoRefreshList && response.jobHuntClients){
                props.updateHist(response.jobHuntClients, response.jobHuntClients.length)
            }
        }
    }

    const handleSetManager = newManage => {
        let paramsToPass = {manager: newManage}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.changePersonalJourneyManager(paramsToPass,(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            console.log(error)
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
        })
    };

    const handleSetStatus = newStatus => {
        let paramsToPass = {status: newStatus}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.changePersonalJourneyStatus(paramsToPass,(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            console.log(error)
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
        })
    }

    const handleSetPlan = newPlan => {
        let paramsToPass = {plan: newPlan}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        JHApi.changePersonalJourneyPlan(paramsToPass,(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            console.log(error)
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
            
            setIsLoading(false)
        })
    }

    const handleSetSeniority = newSeniority => {
        let paramsToPass = {data:{seniorityLevel:newSeniority}}

        if (isAdmin){
            paramsToPass.userId = userId
        }
    
        setIsLoading(true)

        JHApi.updatePersonalJourneyData(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            if(response.setStep){
                setShowedStep(response.personaljourney.currentStep)
            }
        }, (error) => {
            setIsLoading(false)
            console.log(error)
            if(error && error.response&& error.response.data){
                props.showMessageDialog('Error', error.response.data)
            } else {
                props.showMessageDialog('Error', 'Something went wrong')
            }
        })
    }

    const changeJourneyLink = (newLink) => {
        let paramsToPass = {journeyLink:newLink}

        if (isAdmin){
            paramsToPass.userId = userId
        }
        
        setIsLoading(true)

        JHApi.updatePersonalJourneyLink(paramsToPass,(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changeJourneyPhone = (newPhone) => {
        let paramsToPass = {data:{phone:newPhone}}

        if (isAdmin){
            paramsToPass.userId = userId
        }
    
        setIsLoading(true)

        JHApi.updatePersonalJourneyData(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changeSafetyNetExpireDate = safetyNetExpireDate => {
        let paramsToPass = {data:{safetyNetExpireDate}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            postServerCall(null, response)
        }, (error) => {
            console.log(error)
            postServerCall(error)
        })
    }

    const changeCyclesLimit = cyclesLimit => {
        let paramsToPass = {data:{cyclesLimit}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            postServerCall(null, response)
        }, (error) => {
            console.log(error)
            postServerCall(error)
        })
    }

    const changeIsSendFoundJobEmail = event => {
        let paramsToPass = {data:{isSendFoundJobEmail:event.target.checked}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoading(true)

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            postServerCall(null, response)
        }, (error) => {
            console.log(error)
            postServerCall(error)
        })
    }

    const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
    }

    const handlseShowJourney = () => {
        setShowJourney(true)
    }

    const handlseShowDashboard = () => {
        ReactGA.send({ hitType: "pageview", title: 'Dashboard From Jurney' });
        setShowDashboard(true)
    }

    const propsStepsContent = {
        isAdmin,
        journeyData,
        explenations,
        //resumes,
        handleSaveData,
        isLoading,
        userId,
        preServerCall,
        postServerCall,
        converToDocx,
        converToPdf,
        isActiveJHUser
        //handleAddResume,
        //handleUpdateResume
    }

    const internationalPhone = getInternationalPhone(journeyData.phone || '')

    //const waLink = `https://api.whatsapp.com/send/?type=phone_number&phone=`+internationalPhone
    const waLink = 'https://wa.me/'+internationalPhone

    const beginServiceDate = journeyData.beginServiceDate || journeyData.dateCreated

    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    let daysInFreeze = 0
    freezeHistory.forEach(element => {
        if (element.start>=beginServiceDate){
            daysInFreeze += Math.round((new Date(element.end||now) - new Date(element.start))/oneDay)
        }
    });

    const daysInServiceEndDate = signedDate?new Date(signedDate):now

    //const daysSincelLastPublish = journeyData.lastPublishDate?Math.round((now - new Date(journeyData.lastPublishDate))/oneDay):0
    const daysSinceFirstPartSent = journeyData.firstPartSent?Math.round((now - new Date(journeyData.firstPartSent))/oneDay):0
    const daysInService = (beginServiceDate?Math.round((daysInServiceEndDate - new Date(beginServiceDate))/oneDay):0) - daysInFreeze

    const numberOfCycles = extraData.cycles? extraData.cycles.length:0

    const view = (window.innerWidth <= 768)?'MOBILE':'DESKTOP'

    const classes = PersonalJourneyClasses(); 

    const showJourneyTemp = true
    const showAfterSign = true

    return (
        <>
        {journeyData._id&&<>
            <div style={{padding:16, paddingBottom:8}}>
                <div className="jobTitleSection">
                    <div className="jobTitle" style={{color:'#222', width: '100%', fontSize: 24}}>
                        {journeyData.title || ''}
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <UserAvatar positionAbsolute 
                        firstName={journeyData.first_name} 
                        lastName={journeyData.last_name} 
                        avatarSrc={''} 
                        backgroundColor={journeyData.card_color} />
                    {(isAdmin&&view==='DESKTOP')&&<ToggleList/>}
                    {isAdmin&&<div style={{position: 'absolute', right:0, top:0}}>
                        <UserInfo>
                            <Comments {...propsStepsContent}/>
                        </UserInfo>
                    </div>}
                    <div className={"jobInfoSectionInner ltr"}>
                        <div className="jobCompany" style={{color:'rgba(0, 0, 0, 0.54)', fontWeight:'bold'}}>{(journeyData.first_name || '') + ' ' + (journeyData.last_name || '')}</div>
                        {(journeyData.phone)&&<div className="jobLocation" style={{color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>{journeyData.phone || ''}</div>}
                        <div className="jobLocation" style={{textTransform: 'lowercase', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>{journeyData.email || ''}  <span style={{color: 'blue', cursor: 'pointer'}} onClick={()=>setMoreInfo(!moreInfo)}>{moreInfo?'less':'more'} info</span></div>
                        {moreInfo&&<>
                            <div className="jobLocation" style={{textTransform: 'capitalize', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>
                                {daysInService} days in service ({dateAsString(new Date(beginServiceDate))})
                            </div>
                            {(daysInFreeze>0)&&<div className="jobLocation" style={{textTransform: 'capitalize', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>
                                {daysInFreeze} days in freeze
                            </div>}
                            {/*(journeyData.lastPublishDate!==undefined)&&<div className="jobLocation" style={{textTransform: 'capitalize', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>
                                Published {daysSincelLastPublish} days ago
                            </div>*/}
                            <div className="jobLocation" style={{textTransform: 'capitalize', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>
                                Cycles: {numberOfCycles}
                            </div>
                            {(journeyData.firstPartSent!==undefined)&&<div className="jobLocation" style={{textTransform: 'capitalize', color:'rgba(0, 0, 0, 0.54)', lineHeight: 1.5}}>
                                Publication started {daysSinceFirstPartSent} days ago
                            </div>}
                            
                        </>}
                        <div style={{marginTop:4}}>
                            <FieldWithEdit value={journeyData.phone||''} changeValue={changeJourneyPhone}>
                                {(internationalPhone)?<>
                                    <Fab size="small" className={classes.contactButton} href={'tel:'+internationalPhone} color="primary" target='_blank' rel="noopener noreferrer">
                                        <PhoneIcon className={classes.contactButtonIcon} fontSize="small" />
                                    </Fab>
                                    <Fab size="small" className={classes.contactButton} href={waLink} color="primary" target='_blank' rel="noopener noreferrer">
                                        <WhatsAppIcon className={classes.contactButtonIcon} fontSize="small" />
                                    </Fab>
                                </>:
                                <span>{(journeyData.phone)?'Invalid Phone': 'No Phone'}</span>}
                            </FieldWithEdit>
                        </div>
                    </div>
                    <div style={{marginTop:8}}>
                        <UniqueColor value={journeyData.csManager||''}>
                            <SelectAsChip value={journeyData.csManager} onChange={handleSetManager} list={CSManagers} emptyLabel='No Manager' labelFunc={getNameFromEmail} disabled={isLoading} style={{marginRight:8, marginTop:8}} noEdit={!isAdmin}/>
                        </UniqueColor>
                        <UniqueColor value={journeyData.status||''}>
                            <SelectAsChip value={journeyData.status} onChange={handleSetStatus} list={statuses} emptyLabel='No Status' disabled={isLoading} style={{marginRight:8, marginTop:8}} noEdit={!isAdmin}/>
                        </UniqueColor>
                        <UniqueColor value={journeyData.plan||''}>
                            <SelectAsChip value={journeyData.plan} onChange={handleSetPlan} list={plans} emptyLabel='No Plan' disabled={isLoading} style={{marginRight:8, marginTop:8}} noEdit={!isAdmin}/>
                        </UniqueColor>
                        <UniqueColor value={journeyData.seniorityLevel||''}>
                            <SelectAsChip value={journeyData.seniorityLevel} onChange={handleSetSeniority} list={SENIORITIES_LIST} emptyLabel='No Seniority' disabled={isLoading} style={{marginRight:8, marginTop:8}}/>
                        </UniqueColor>
                        {isAdmin&&<SafetyNet {...propsStepsContent} style={{marginRight:8, marginTop:8}}/>}
                        {(isAdmin&&journeyData.subscriptionId&&!journeyData.isSubscriptionClosed&&!journeyData.tashlumim&&!journeyData.hideSubscriptionCloseButton)&&
                            <Subscription {...propsStepsContent} style={{marginRight:8, marginTop:8, backgroundColor: 'green'}}/>
                        }
                    </div>
                    {(showAfterSign&&isAdmin&&journeyData.status==='signed')&&<div>
                        <br/><FormControlLabel
                            checked={journeyData.isFoundJobEmailSent||false}
                            control={<Switch color="primary"/>}
                            label="Found Job Email Sent"
                            labelPlacement="end"
                            disabled
                        /><br/>
                        <FormControlLabel
                            onChange={changeIsSendFoundJobEmail}
                            checked={journeyData.isSendFoundJobEmail||false}
                            control={<Switch color="primary"/>}
                            label="Send Found Job Email"
                            labelPlacement="end"
                            disabled={isLoading}
                        /><br/>
                        <strong>Expire safety net date: </strong>
                        <FieldWithEdit value={journeyData.safetyNetExpireDate||''} changeValue={changeSafetyNetExpireDate} fieldInputType="date">
                            <span>{dateAsString(new Date(journeyData.safetyNetExpireDate))||'No expire safety net date'}</span>
                        </FieldWithEdit><br/>
                        <strong>publication limit: </strong>
                        <FieldWithEdit value={journeyData.cyclesLimit||''} changeValue={changeCyclesLimit} fieldInputType="number">
                            <span>{journeyData.cyclesLimit||'No publication limit'}</span>
                        </FieldWithEdit>
                    </div>}
                    <div style={{marginTop:16, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                        {showJourneyTemp&&<>
                        <Button color='primary' variant='contained' size='small' onClick={handlseShowJourney} style={{textTransform:'capitalize', marginRight: 8}}>Full Journey View</Button>
                        <Journey {...propsStepsContent} showJourney={showJourney} setShowJourney={setShowJourney} freezeHistory={freezeHistory}/>
                        </>}
                        <Button color='primary' variant='contained' size='small' onClick={handlseShowDashboard} style={{textTransform:'capitalize', marginRight: 8}}>Dashboard</Button>
                        {showDashboard&&<DashboarDialog showDashboard={showDashboard} setShowDashboard={setShowDashboard} isAdmin={isAdmin} userId={userId}/>}
                        </div>
                        <div>
                        {(isAdmin||journeyData.journeyLink)&&<FieldWithEdit value={journeyData.journeyLink||''} changeValue={changeJourneyLink} noEdit={!isAdmin}>
                            <span style={{padding: '16px 0'}}>
                                {(journeyData.journeyLink)?<a target='_blank' rel="noopener noreferrer" href={journeyData.journeyLink}>Old Journey</a>:
                                <span>No Link</span>}
                            </span>
                        </FieldWithEdit>}
                        </div>
                    </div>
                    {/*<Grid container spacing={1} style={{marginTop:16}}>
                        <Grid item sm={8} xs={12}>
                            <UpcomingMeeting {...propsStepsContent} upcomingMeeting={upcomingMeeting} CSManagers={CSManagers}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <FieldWithEdit value={journeyData.journeyLink||''} changeValue={changeJourneyLink}>
                                {(journeyData.journeyLink)?<a target='_blank' rel="noopener noreferrer" href={journeyData.journeyLink}>Journey Link</a>:
                                <span>No Link</span>}
                            </FieldWithEdit>
                        </Grid>
                    </Grid>*/}
                </div>
            </div>
            {isLoadingAll ? <div style={{textAlign: 'center'}}><CircularProgress/></div>:<>
            {isActiveJHUser&&<>
                    <Tabs value={tabSelected} onChange={handleChangeTab} variant="scrollable" color='primary' indicatorColor="primary">
                        <Tab value={0} label="Publication Steps" className={classes.journeyTab} disabled={isLoading}/>
                        <Tab value={1} label="CV + Cover" className={classes.journeyTab} disabled={isLoading}/>
                        {/*<Tab value={2} label="Journey" className={classes.journeyTab} disabled={isLoading}/>*/}
                        <Tab value={3} label="Interviews" className={classes.journeyTab} disabled={isLoading}/>
                        <Tab value={4} label="Social" className={classes.journeyTab} disabled={isLoading}/>
                        <Tab value={5} label="Dream Job" className={classes.journeyTab} disabled={isLoading}/>
                        <Tab value={6} label="Jobs & Offers" className={classes.journeyTab} disabled={isLoading}/>
                        {(isAdmin||journeyData.isEnableProjects)&&<Tab value={7} label="Projects" className={classes.journeyTab} disabled={isLoading}/>}
                    </Tabs>
                    {(tabSelected === 0) && <>
                        <Stepper className={classes.stepper} nonLinear activeStep={showedStep} orientation="vertical">
                            {PESONAL_JOURNEY_STEPS.map((step, index) => (
                            <Step key={step} disabled={isLoading || (index > journeyData.currentStep)}>
                                <StepButton onClick={() => handleStep(index)}>
                                    <StepLabel>{step} {(index === showedStep)&&<PersonalJourneyExplainNew explenations={explenations} index={index}/>}</StepLabel>
                                </StepButton>
                                <StepContent>
                                    {(index === showedStep && index === 0)&&<TitleDetailsStep {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 1)&&<CoverLetterStep {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 2)&&<CVStep {...propsStepsContent} step={index} uplaodMessage={uplaodMessage} uploadPublishCVFile={uploadPublishCVFile}/>}
                                    {(index === showedStep && index === 3)&&<ExcludeDetailsStep {...propsStepsContent} step={index} updatePublish={updatePublish}/>}
                                    {(index === showedStep && index === 4)&&<TestEmailStep {...propsStepsContent} step={index} sendTest={sendTest} startPublish={startPublish}/>}
                                    {(index === showedStep && index === 5)&&<PublishStep {...propsStepsContent} step={index} updatePublish={updatePublish} cycles={extraData.cycles} upcomingPublish={extraData.upcomingPublish}
                                        publishData={extraData.publishData}/>}

                                    {(journeyData.currentStep===showedStep&&[1,2,3].includes(showedStep))&&<Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleNextStep} aria-label="Complete Step">Complete Step</Button>}
                                    {/*(journeyData.currentStep!==0)&&<StepsButton handleBackStep={handleBackStep} handleNextStep={handleNextStep} isLoading={isLoading} showedStep={showedStep} currentStep={journeyData.currentStep}/>*/}
                                    {/*(index === showedStep && index === 0)&&<CoverLetterStep {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 1)&&<TitleStep {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 2)&&<PrePublish {...propsStepsContent} step={index} uplaodMessage={uplaodMessage} uploadPublishCVFile={uploadPublishCVFile} sendTest={sendTest} startPublish={startPublish}/>}
                                    {(index === showedStep && index === 3)&&<PublishStep {...propsStepsContent} step={index} newPublish={newPublish}/>*/}
                                    {/*(index === showedStep && index === 0)&&<FillYourDreamJob {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 1)&&<PutEverythingOnPaper {...propsStepsContent} step={index}/>}
                                    {(index === showedStep && index === 2)&&<CreateCvsAndCoverLetters {...propsStepsContent} step={index}/>*/}
                                    {/*{(index === showedStep && index === 3)&&<Linkedin {...propsStepsContent} step={index}/>}*/}
                                    {/*(index === showedStep && index === 3)&&<PrePublish {...propsStepsContent} step={index}/>*/}
                                    {/*{(index === showedStep && index === 5)&&<Interviews {...propsStepsContent} step={index}*/}
                                </StepContent>
                            </Step>
                            ))}
                        </Stepper>
                    </>}
                    {(tabSelected === 1)&&<CVCover {...propsStepsContent}/>}
                    {/*(tabSelected === 2)&&<Timeline {...propsStepsContent}/>*/}
                    {(tabSelected === 3)&&<Interviews {...propsStepsContent}/>}
                    {(tabSelected === 4)&&<Social {...propsStepsContent}/>}
                    {(tabSelected === 5)&&<DreamJob {...propsStepsContent}/>}
                    {(tabSelected === 6)&&<JobsFound {...propsStepsContent} numberOfCycles={numberOfCycles} freezeHistory={freezeHistory} signedDate={signedDate}/>}
                    {(tabSelected===7&&(isAdmin||journeyData.isEnableProjects))&&<Projects {...propsStepsContent}/>}
                    </>}
                </>}
            {/*tabSelected === 1 && <Interviews userId={userId} isAdmin={isAdmin} personalJourneyId={journeyData._id} showMessageDialog={props.showMessageDialog}/>*/}
        </>}
        </>
    )
}